@font-face{
  font-family: "NotFontDisplay-Light";
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/NotFontDisplay-Light.otf);
  src: url(./assets/fonts/NotFontDisplay-Light.otf) format("embedded-opentype"),
  url(./assets/fonts/NotFontDisplay-Light.woff2) format("woff2"),
  url(./assets/fonts/NotFontDisplay-Light.woff) format("woff");
}

@font-face{
  font-family: "NotFontDisplay-Regular";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/NotFontDisplay-Regular.otf);
  src: url(./assets/fonts/NotFontDisplay-Regular.otf) format("embedded-opentype"),
  url(./assets/fonts/NotFontDisplay-Regular.woff2) format("woff2"),
  url(./assets/fonts/NotFontDisplay-Regular.woff) format("woff");
}

@font-face{
  font-family: "NotFontDisplay-Titling";
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/NotFontDisplay-Titling.otf);
  src: url(./assets/fonts/NotFontDisplay-Titling.otf) format("embedded-opentype"),
  url(./assets/fonts/NotFontDisplay-Titling.woff2) format("woff2"),
  url(./assets/fonts/NotFontDisplay-Titling.woff) format("woff");
}

@font-face{
  font-family: "NotFontDisplay-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/NotFontDisplay-SemiBold.otf);
  src: url(./assets/fonts/NotFontDisplay-SemiBold.otf) format("embedded-opentype"),
  url(./assets/fonts/NotFontDisplay-SemiBold.woff2) format("woff2"),
  url(./assets/fonts/NotFontDisplay-SemiBold.woff) format("woff");
}

@font-face{
  font-family: "NotFontDisplay-Bold";
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/NotFontDisplay-Bold.otf);
  src: url(./assets/fonts/NotFontDisplay-Bold.otf) format("embedded-opentype"),
  url(./assets/fonts/NotFontDisplay-Bold.woff2) format("woff2"),
  url(./assets/fonts/NotFontDisplay-Bold.woff) format("woff");
}

body {
  margin: 0;
  font-family: "NotFontDisplay-Regular",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-hero {
  min-width: 100vw;
}

.results {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.results-title {
  font-family: 'NotFontDisplay-Bold';
  font-weight: bold;
  color: black;
  font-size: 2rem;
  text-align: center;
  align-self: center;
  line-height: 95%;
  letter-spacing: 0.02em;
}

.results-subtitle {
  font-family: NotFontDisplay-Light;
  font-weight: bold;
  color: black;
  font-size: 1.25rem;
  text-align: center;
  align-self: center;
  line-height: 140%;
  letter-spacing: 0.02em;
}

strong {
  font-family: NotFontDisplay-Bold;
}

.restaurant-card {
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  border: 1px solid #e7e7e7;
  padding: 0.5rem;
  cursor: pointer;
  transition: 0.25s;
  background-color: white;
  justify-content: center;
}

.restaurant-card:hover {
  box-shadow: 0px 3px 5px rgba(0,0,0,0.1);
}

.restaurant-card .title {
  font-weight: bold;
  font-size: 1rem;
  font-family: NotFontDisplay-Titling;
}

.restaurant-card .subtitle {
  font-size: 1rem;
  color: #706967;
}

.restaurant-card .info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  justify-content: space-between;
  width: 100%;
  margin: 0 1rem;
}

.restaurant-card.selected {
  border: 3px solid #ffcd00;
}

.buy-button {
  border-radius: 2rem;
  background-color: #ffcd00;
  color: black;
  font-family: NotFontDisplay-SemiBold;
  font-size: 0.75rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  display: inline-block;
  text-align: center;
  width: fit-content;
  padding: 0.25rem 1.25rem;
}

.burger-image {
  height: 10rem;
  min-width: 10rem;
  object-fit: cover;
  border-radius: 0.75rem;
  object-fit: cover;
}

.restaurant-logo {
  min-height: 9rem;
  min-width: 9rem;
  width: 9rem;
  object-fit: contain;
}

.restaurant-logo {
  min-height: 9rem;
  min-width: 9rem;
  width: 9rem;
  object-fit: contain;
}

.restaurant-logo-small {
  min-height: 3rem;
  min-width: 3rem;
  width: 3rem;
  object-fit: contain;
}

@media (max-width: 700px) {
  .burger-image {
    height: 6.5rem;
    min-width: 6.5rem;
  }

  .restaurant-logo {
    min-height: 4rem;
    min-width: 4rem;
    width: 4rem;
    height: 4rem;
  }

  .restaurant-card .info {
    margin: 0 0.5rem;
  }
}

.neighborhood {
  font-size: 0.75rem;
  color: #6e6e6e;
}

.marker {
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  color: #6e6e6e;
  margin-right: 0.25rem;
}

a {
  text-decoration: none;
  color: initial;
}

a:hover {
  color: initial;
}

.results-subtitle p {
  margin: 0;
}

.results-subtitle .small {
  color: #6e6e6e;
}

.loader {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: black;
}

.footer-logo {
  object-fit: contain;
}

.footer-link {
  font-size: 1rem;
  color: white;
  display: block;
  margin: 0.5rem 0;
  cursor: pointer;
}

.footer-link:hover {
  color: #E81F76;
}

.footer-title {
  display: block;
  color: white;
  font-family: NotFontDisplay-SemiBold;
}

.mayo {
  color: #E81F76;
}

.milk {
  color: #00B4FF;
}

.icecream {
  color: #9B00FF;
}

.burger {
  color: #FFCD00;
}

.find-us-logos {
  display: flex;
  flex-direction: row;
}

.social-icon {
  margin-right: 1rem;
}

@media (max-width: 700px) {
  .find-us-logos {
    width: 100%;
    justify-content: space-between;
  }

  .hide-on-mobile {
    display: none;
  }
}

@keyframes scroll_2 {
  0%   { transform: translateY(0); opacity: 1; }
  25%  { opacity: 1; }
  75%  { transform: translateY(.75em); opacity: 0; }
  100% { transform: translateY(0); opacity: 0; }
}

.example--2 {
  background: cornflowerblue;
}

.scroll-icon__dot {
  display: block;
  position: absolute;
  left: 50%;
  background: #fff;
  height: .5em;
  width: .5em;
  top: .6em;
  margin-left: -.25em;
  border-radius: 50%;
  transform-origin: top center;
  backface-visibility: hidden;
  animation: scroll_2 2s ease-out infinite;
}

.scroll-icon {
  display: block;
  height: 3em;
  width: 1.5em;
  border: .15em solid #fff;
  border-radius: 1em;
}

.scroll-container {
  position: absolute;
  bottom: 13%;
  left: 49%;
}

.view-results {
  color: white;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 8%;
}

.contact {
  color: #FFCD00;
  cursor: pointer;
}

.contact:hover {
  color: #d0a400;
}

.contact-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFCD00;
}

.contact-title {
  text-transform: uppercase;
  color: white;
  font-size: 1.25rem;
  font-family: NotFontDisplay-SemiBold;
}

.contact-title span {
  color: black;
}

.contact-button {
  background-color: black;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
  color: white;
  font-family: NotFontDisplay-SemiBold;
  cursor: pointer;
}

.contact-button:hover {
  color: #FFCD00;
}

.switch {
  border: 1px solid #e7e7e7;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.switch div {
  width: 50%;
  padding: 0.5rem;
  border-radius: 3rem;
  text-align: center;
  font-family: NotFontDisplay-Bold;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
}

.switch div.selected {
  background-color: #ffcd00;
}

.search {
  border: 1px solid #e7e7e7;
  border-radius: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.search img {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1rem;
}

.search div {
  padding: 0.5rem 2rem;
  background-color: #ffcd00;
  border-radius: 3rem;
  text-align: center;
  font-family: NotFontDisplay-Bold;
  margin: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
}

.search input {
  border: none;
  width: 100%;
  outline: none;
  margin: 0.5rem 0.5rem;
}

.map-popup {
  background-color: white;
  color: black;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-top: 58px;
}

.marker-logo {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 2.5rem;
  background-color: white;
  object-fit: contain;
}

.tooltip-title {
  font-family: NotFontDisplay-Titling;
}
